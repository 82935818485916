// Typo variables
$family-sans-serif: 'Open Sans', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

// Set your colors
$primary: #DE134E;
$primary-invert: #fff;
$light: #EAEAEA;
$grey: #7A7A7AFF;
$grey-white: #F9F9F9;
$grey-lighter: #EFEFEF;
$grey-darker: #1F1F1F;
$danger: #EA1742;
$success: #3BC173;
$red: #EA1742;
$text: $grey-darker;

// Heading sizes
$size-1: 2.25rem;
$size-2: 1.5rem;
$size-3: 1.125rem;
$size-4: 1.5rem; // ?
$size-5: 1.25rem; // ?
$size-6: 1rem; // ?
$size-7: 0.75rem; // ?

// Radius
$radius: 6px;
$radius-large: $radius * 2;
$radius-rounded: $radius * 4;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Form controls
$control-padding-vertical: .7rem;
$control-padding-horizontal: 1.1rem;
$input-focus-box-shadow-size: 0;
$input-disabled-border-color: $grey-lighter;
$input-focus-border-color: $grey;
$input-shadow: none;

// Tags
$tag-radius: 3px;

// Box
$box-shadow: 0px 21px 45px 0px rgb(213 213 213 / 55%);
$box-padding: 2.5rem;

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Import the rest of Bulma (from bulma.sass)
@import "~bulma/sass/base/_all";

// Elements (from bulma/sass/elements/_all)
@import "~bulma/sass/elements/box.sass";
//@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";
//@import "~bulma/sass/elements/icon.sass";
//@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/notification.sass";
//@import "~bulma/sass/elements/progress.sass";
@import "~bulma/sass/elements/table.sass";
@import "~bulma/sass/elements/tag.sass";
@import "~bulma/sass/elements/title.sass";
//@import "~bulma/sass/elements/other.sass";

@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";


// Buefy (~buefy/src/scss/buefy)
@import "~buefy/src/scss/utils/_all";
//@import "~buefy/src/scss/components/_autocomplete";
//@import "~buefy/src/scss/components/_carousel";
//@import "~buefy/src/scss/components/_checkbox";
//@import "~buefy/src/scss/components/_clockpicker";
//@import "~buefy/src/scss/components/_collapse";
//@import "~buefy/src/scss/components/_datepicker";
@import "~buefy/src/scss/components/_dialog";
//@import "~buefy/src/scss/components/_dropdown";
@import "~buefy/src/scss/components/_form";
//@import "~buefy/src/scss/components/_icon";
//@import "~buefy/src/scss/components/_image";
@import "~buefy/src/scss/components/_loading";
//@import "~buefy/src/scss/components/_menu";
//@import "~buefy/src/scss/components/_message";
@import "~buefy/src/scss/components/_modal";
@import "~buefy/src/scss/components/_navbar";
@import "~buefy/src/scss/components/_notices";
//@import "~buefy/src/scss/components/_numberinput";
@import "~buefy/src/scss/components/_pagination";
//@import "~buefy/src/scss/components/_progress";
@import "~buefy/src/scss/components/_radio";
//@import "~buefy/src/scss/components/_rate";
//@import "~buefy/src/scss/components/_select";
//@import "~buefy/src/scss/components/_skeleton";
//@import "~buefy/src/scss/components/_sidebar";
//@import "~buefy/src/scss/components/_slider";
//@import "~buefy/src/scss/components/_steps";
@import "~buefy/src/scss/components/_switch";
@import "~buefy/src/scss/components/_table";
//@import "~buefy/src/scss/components/_tabs";
//@import "~buefy/src/scss/components/_tag";
//@import "~buefy/src/scss/components/_taginput";
//@import "~buefy/src/scss/components/_timepicker";
@import "~buefy/src/scss/components/_tooltip";
@import "~buefy/src/scss/components/_upload";

// Extra custom base CSS
.title {
	&.is-2,
	&.is-3,
	&.is-4,
	&.is-5 {
		margin-bottom: 0.5rem;
	}
}
.content {
	.is-2,
	.is-3,
	.is-4,
	&.is-5 {
		margin-bottom: 0.5rem;
	}
}

@media screen and (max-width: 680px) {
	.title {
		&.is-1 {
			font-size: 1.75rem;
		}
	}
}

.section {
	&.is-highlighted {
		background-color: #F1F5F8;
	}
}

.box {
	border-radius: $radius;
}
